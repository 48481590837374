<template>
  <div>
    <CRow>
      <CCol sm="12">
        <UsersTable
          :items="users"
          hover
          striped
          border
          small
          fixed
          caption="Usuarios"
          @refresh="refreshItems"
        />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import UsersTable from '../components/users/UsersTable.vue'
import users from '../services/users';
import store from '../store'

export default {
  name: 'Users',
  components: { UsersTable },
  data: function () {
		return {
            users: []
        }
  },
  mounted: async function() {
    this.loading();
    let response = await users.get();

    if(response.type == "success"){
      this.users = response.data;
    }
    this.loaded();
  },
  methods: {
    async refreshItems (items){
      this.loading();
      this.users = await items;
      this.loaded();
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
